.app-page-wrap-container.GestionarCitas {
  width: max(1400px, 80%) !important;
  margin: 0 30px;

  .app-page-container {
    width: 100%;
    height: 100%;

    .h2.error {
      font-size: 2rem;
      font-weight: 400;
      color: #f5f5f5;
      line-height: 2rem;
      text-align: center;
    }
  }
}