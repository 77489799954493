.inputCalendar {
    .p-calendar {
        color: #333333;
        width: 100px;

        .p-inputtext {
            width: 100%;
            height: 25.6px;
            outline: none;
            border: none;
            border-bottom: 2px solid lightgray;
            background-color: transparent;
            border-radius: 10px 10px 0 0;
            font-size: 14px;
            color: rgb(29, 29, 29);;
            padding: 0 0 4px 0;
        }
    }
}


.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem !important;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem !important;
}