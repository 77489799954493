@import "../../Styles/Global.scss";

.registros-table-container {
  max-height: 400px;
  width: 100%;
  overflow-y: auto; // Agregar desplazamiento vertical si la tabla excede la altura máxima
  position: relative;

  &.small {
    .registros-table {

      th,
      td {
        font-size: 13px;
        color: rgb(29, 29, 29);
      }
    }
  }

  .registros-table {
    width: 100%;
    border-collapse: collapse;

    border-radius: 10px;
    overflow: hidden;

    thead {
      background-color: #CA7F5A;
    }

    th,
    td {
      padding: 10px;
      border-bottom: 1px solid #ccc;
      background-color: #f1dcd2;
      text-align: left;
      font-size: 14px; // Tamaño de fuente aumentado
      line-height: 1.2;
    }

    th {
      background-color: $primary-color;
      color: white;
      position: sticky; // Hacer que la cabecera sea fija
      top: 0; // Fijar la cabecera en la parte superior
      z-index: 1; // Asegurarse de que la cabecera esté sobre otras filas
    }

    tbody tr {
      &:hover {
        background-color: #f0f0f0;
      }

      .notRowOnFocus {
        filter: grayscale(1);
      }
    }

    input {
      outline: none;
      width: 100px;
      padding: 0;
      border: none;
      background-color: #f1dcd2;
      text-align: left;
      font-size: 14px; // Tamaño de fuente aumentado
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      /* Sombra suave */
    }

    textarea {
      width: 100%;
      box-sizing: border-box;
      // padding: 10px 15px;

      border-radius: 15px;
      background-color: #f1dcd2;
      outline: none;
      border: none;

      padding: 10px;
      font-size: 14px;
      color: #333;

      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: box-shadow 0.3s ease;
      /* Transición para la sombra */

      &:hover,
      &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        /* Sombra suave */
      }
    }

    select {
      font-family: 'Arial', sans-serif;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      color: #333;
      outline: none;
      transition: border-color 0.3s ease;
    }

    select {
      width: 100%;
      box-sizing: border-box;
      // padding: 10px 15px;

      border-radius: 15px;
      background-color: #f1dcd2;
      outline: none;
      border: none;

      font-size: 14px;
      font-weight: 700;
      color: #333;

      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: box-shadow 0.3s ease;
      /* Transición para la sombra */
    }

    select:hover,
    select:focus {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      /* Sombra suave */
    }

    select option {
      cursor: pointer;
      color: #ececec;
      background-color: #312f2f;
    }

  }
}