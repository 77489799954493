div.drop {
    .p-dropdown {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 2px solid lightgray;
        background-color: transparent;
        border-radius: 10px 10px 0 0;
        font-size: clamp(20px, 2vw, 22px);
        color: #e5e5e5;

        .p-dropdown-trigger {
            color: white;
        }

        .p-dropdown-clear-icon {
            color: white;
            padding-bottom: 5px;
        }

        .p-dropdown-label {
            color: white;
            padding: 0 0 1px 0;
            line-height: 1;
            font-family: "Archivo", sans-serif;
            font-size: clamp(20px, 2vw, 22px);
            color: #e5e5e5;
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none !important;
        border-color: lightgray;
    }
}